import React from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Box,
  Paper,
  Stack,
  ButtonBase,
  Autocomplete,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { Text, TextInput } from "../../component";
import moment from "moment";
import {
  getListPencairan,
  getNasabahAcc,
  getPenyaluranNasabah1,
  uploadNasabahAcc,
  uploadPenyaluranNasabah,
} from "../../stores";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

function ListPencairan() {
  let history = useNavigate();
  const dispatch = useDispatch();
  const pencairan = useSelector((state) => state.dashboardReducer);
  const [open, setOpen] = React.useState(false);

  const [listPencairan, setListPencairan] = React.useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const handleClose = () => {
    setOpen(false);
  };

  const customStyles = {
    rows: {
      style: {
        height: 36, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 36,
        backgroundColor: "#F8F8F8",
        fontFamily: "Poppins",
        justifyContent: "left",
      },
    },
    headCells: {
      style: {
        minHeight: 36,
        fontWeight: "bold",
        fontSize: 12,
      },
    },
    cells: {
      style: {
        minHeight: 36,
      },
    },
  };

  //Filter
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems =
    listPencairan &&
    listPencairan.filter(
      (item) =>
        item.nama && item.nama.toLowerCase().includes(filterText.toLowerCase())
    );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Box sx={{ width: "200px" }} marginBottom={3}>
        <TextInput
          id="standard-basic"
          variant="standard"
          type={"text"}
          onChange={(e) => setFilterText(e.target.value)}
          fullWidth={true}
          InputProps={{
            style: {
              fontSize: 12,
              fontWeight: "400",
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder={"Cari Nama Nasabah"}
        />
      </Box>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "Nama Nasabah",
      minWidth: "200px",
      selector: (row) => row.nama,
    },
    {
      name: "NIK KTP",
      minWidth: "200px",
      selector: (row) => row.ktp,
    },
    {
      name: "ID Cabang",
      minWidth: "200px",
      selector: (row) => row.cabangRealisasi,
    },
    {
      name: "Tanggal Akad",
      minWidth: "200px",
      selector: (row) => row.tanggalAkad,
    },
    {
      name: "Permohonan",
      minWidth: "200px",
      selector: (row) => row.nominalPermohonans,
    },
    {
      name: "Pencarian",
      minWidth: "200px",
      selector: (row) => row.nominalPencairans,
    },
  ];

  React.useEffect(() => {
    getListPencairan(dispatch);
  }, []);

  React.useEffect(() => {
    let tmp =
      pencairan.listPencairan &&
      pencairan.listPencairan.map((val) => {
        return {
          ...val,
          nominalPermohonans: (
            <NumericFormat
              value={val.nominalPermohonan}
              thousandSeparator={true}
              prefix={"Rp"}
              displayType={"text"}
            />
          ),
          nominalPencairans: (
            <NumericFormat
              value={val.nominalPencairan}
              thousandSeparator={true}
              prefix={"Rp"}
              displayType={"text"}
            />
          ),
          tanggalAkad: moment(val.tglAkad).format("DD MMMM YYYY"),
        };
      });
    setListPencairan(tmp);
  }, [pencairan.listPencairan]);

  return (
    <div>
      {/* <Stack flexDirection={"row"}>
        <Box flexGrow={1}>
          <Button
            color="success"
            // startIcon={<PersonPinCircleIcon />}
            variant="contained"
            size="small"
            onClick={handleClickOpen}
            style={{ textTransform: "none", marginLeft: 10, marginBottom: 20 }}
          >
            Upload Data Nasabah
          </Button>
        </Box>
      </Stack> */}

      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        customStyles={customStyles}
        // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
      />
    </div>
  );
}

export default ListPencairan;

import React from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Box,
  Paper,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { Text } from "../../component";
import {
  getPenyaluranTiapCabang,
  getPenyaluranTiapCabang2,
} from "../../stores";
import { RangeDatePicker } from "react-google-flight-datepicker";
import moment from "moment";
import { NumericFormat } from "react-number-format";

function LaporanCabang() {
  const dispatch = useDispatch();
  const karyawan = useSelector((state) => state.karyawanReducer);
  const [listPenyaluran, setPenyaluran] = React.useState([]);
  const [startDate, setStartDate] = React.useState(
    new Date(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMinutes(
        new Date().getTimezoneOffset() * -1
      )
    )
  );
  const [endDate, setEndDate] = React.useState(
    new Date(new Date().setMinutes(new Date().getTimezoneOffset() * -1))
  );
  const customStyles = {
    rows: {
      style: {
        height: 36, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 36,
        backgroundColor: "#F8F8F8",
        fontFamily: "Poppins",
        justifyContent: "left",
      },
    },
    headCells: {
      style: {
        minHeight: 36,
        fontWeight: "bold",
        fontSize: 12,
      },
    },
    cells: {
      style: {
        minHeight: 36,
      },
    },
  };
  const columns = [
    {
      name: "Nama Cabang",
      sortable: true,
      selector: (row) => row.namaCabang,
    },
    {
      name: "Target",
      sortable: true,
      selector: (row) => (
        <NumericFormat
          value={Math.round(row.target)}
          thousandSeparator={true}
          prefix={"Rp "}
          displayType={"text"}
        />
      ),
    },

    {
      name: "Penyaluran",
      sortable: true,
      selector: (row) => (
        <NumericFormat
          value={Math.round(row.penyaluran)}
          thousandSeparator={true}
          prefix={"Rp "}
          displayType={"text"}
        />
      ),
    },

    {
      name: "Persentase Penyaluran",
      sortable: true,
      selector: (row) => `${Math.round(row.persentase)} %`,
    },
  ];

  React.useEffect(() => {
    getPenyaluranTiapCabang(dispatch, {
      from: startDate.getTime(),
      to: endDate.getTime(),
    });

    // getPenyaluranTiapCabang2(dispatch, {
    //   from: startDate.getTime(),
    //   to: endDate.getTime(),
    // });
  }, [startDate, endDate]);

  React.useEffect(() => {
    let tmp = [];
    karyawan.responPenyaluranCabang &&
      karyawan.responPenyaluranCabang.map((val) => {
        if (val.target != undefined) {
          let penyaluran =
            val.penyaluran.length === 0 ? 0 : val.penyaluran[0].totalPenyaluran;
          let target =
            val.target?.length === 0 || val.target[0].target === 0
              ? 0
              : val.target[0].target;
          tmp.push({
            ...val,
            penyaluran: penyaluran,
            target: target,
            persentase: target === 0 ? 0 : (penyaluran / target) * 100,
          });

          setPenyaluran(tmp);
        }
      });
  }, [karyawan.responPenyaluranCabang]);

  return (
    <div>
      <Box width={"40%"} marginTop={"20px"} marginBottom={"20px"}>
        <Text
          text={"Filter Tanggal"}
          sx={{ fontSize: 18, color: "#000000", fontWeight: "600" }}
        />
        <RangeDatePicker
          startDate={startDate}
          endDate={endDate}
          onChange={(startDate, endDate) => {
            setStartDate(
              new Date(
                startDate.setMinutes(new Date().getTimezoneOffset() * -1)
              )
            );
            setEndDate(
              new Date(endDate.setMinutes(new Date().getTimezoneOffset() * -1))
            );
          }}
          dateFormat="DD/MM/YYYY"
          startDatePlaceholder="Start Date"
          endDatePlaceholder="End Date"
          disabled={false}
          className="my-own-class-name"
          startWeekDay="monday"
        />
      </Box>
      <DataTable
        columns={columns}
        data={listPenyaluran}
        pagination
        customStyles={customStyles}
      />
    </div>
  );
}

export default LaporanCabang;

const initialState = {
  jumlahPencairan: 0,
  dashBoaardTotalMarketing: 0,
  dashBoaardTotalSpv: 0,
  dashboardTotalCabang: 0,
  dashboardGrafikCabang: [],
  dashboardJumlahTarget: 0,
  listPencairan: [],
};

const dashboardReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_JUMLAHPENCAIRAN":
      return {
        ...state,
        jumlahPencairan: data,
      };
    case "SET_JUMLAHMARKETING":
      return {
        ...state,
        dashBoaardTotalMarketing: data,
      };
    case "SET_JUMLAHSPV":
      return {
        ...state,
        dashBoaardTotalSpv: data,
      };
    case "SET_JUMLAHCABANG":
      return {
        ...state,
        dashboardTotalCabang: data,
      };
    case "SET_GRAFIKCABANG":
      return {
        ...state,
        dashboardGrafikCabang: data,
      };
    case "SET_JUMLAHTARGET":
      return {
        ...state,
        dashboardJumlahTarget: data,
      };
    case "SET_LISTPENCAIRAN":
      return {
        ...state,
        listPencairan: data,
      };
    default:
      return state;
  }
};

export default dashboardReducer;

import React from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCabang,
  editNamaCabang,
  getAllCabang,
  tambahCabangBaru,
} from "../../stores";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Box,
  Paper,
  Stack,
  TextField,
  Autocomplete,
  InputAdornment,
  IconButton,
  ButtonBase,
  Snackbar,
} from "@mui/material";
import Swal from "sweetalert2";
import { Text, TextInput } from "../../component";
import SearchIcon from "@mui/icons-material/Search";
function Lokasi() {
  const dispatch = useDispatch();
  const karyawan = useSelector((state) => state.karyawanReducer);
  const [openTambah, setOpenTambah] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [namaCabang, setNamaCabang] = React.useState("");
  const [idCabang, setIdCabang] = React.useState("");
  const [listCabang, setListCabang] = React.useState([]);

  //Filter
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems =
    listCabang &&
    listCabang.filter(
      (item) =>
        item.namaCabang &&
        item.namaCabang.toLowerCase().includes(filterText.toLowerCase())
    );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Box sx={{ width: "200px" }} marginBottom={3}>
        <TextInput
          id="standard-basic"
          variant="standard"
          type={"text"}
          onChange={(e) => setFilterText(e.target.value)}
          fullWidth={true}
          InputProps={{
            style: {
              fontSize: 12,
              fontWeight: "400",
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder={"Cari Nama Cabang"}
        />
      </Box>
    );
  }, [filterText, resetPaginationToggle]);

  React.useEffect(() => {
    getAllCabang(dispatch);
  }, []);

  React.useEffect(() => {
    let tmp = [];
    karyawan.responGetCabang.map((val) => {
      return tmp.push(val);
    });
    setListCabang(tmp);
  }, [karyawan.responGetCabang]);

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleClickOpenTambah = () => {
    setOpenTambah(true);
  };

  const handleCloseTambah = () => {
    setOpenTambah(false);
  };

  const customStyles = {
    rows: {
      style: {
        height: 36, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 36,
        backgroundColor: "#F8F8F8",
        fontFamily: "Poppins",
        justifyContent: "left",
      },
    },
    headCells: {
      style: {
        minHeight: 36,
        fontWeight: "bold",
        fontSize: 12,
      },
    },
    cells: {
      style: {
        minHeight: 36,
      },
    },
  };
  const columns = [
    {
      name: "Nama Cabang",
      selector: (row) => row.namaCabang,
      sortable: true,
    },
    {
      name: "Supervisor",
      selector: (row) =>
        !row.identitasSPV ? (
          "Belum memiliki SPV"
        ) : (
          <b>{row.identitasSPV.nama}</b>
        ),
    },
    // {
    //   name: "Aksi",
    //   minWidth: "250px",
    //   selector: (row) => (
    //     <>
    //       <Button
    //         onClick={() => {
    //           setIdCabang(row._id);
    //           setNamaCabang(row.namaCabang);
    //           handleClickOpenEdit();
    //         }}
    //         variant="contained"
    //         sx={{ textTransform: "none" }}
    //       >
    //         Edit
    //       </Button>
    //       <Button
    //         color="error"
    //         onClick={() => {
    //           Swal.fire({
    //             title: `Apakah kamu yakin untuk menghapus ${row.namaCabang} ? `,
    //             // text: "You won't be able to revert this!",
    //             icon: "warning",
    //             showCancelButton: true,
    //             confirmButtonColor: "#3085d6",
    //             cancelButtonColor: "#d33",
    //             confirmButtonText: "Yes, delete it!",
    //           }).then((result) => {
    //             if (result.isConfirmed) {
    //               deleteCabang(row._id).then((respon) => {
    //                 if (respon === 200) {
    //                   getAllCabang(dispatch);
    //                 }
    //               });
    //             }
    //             getAllCabang(dispatch);
    //           });
    //         }}
    //         variant="contained"
    //         sx={{ textTransform: "none", marginX: 2 }}
    //       >
    //         Delete
    //       </Button>
    //     </>
    //   ),
    // },
  ];

  return (
    <div>
      {/* <Box>
        <Button
          color="success"
          // startIcon={<PersonPinCircleIcon />}
          variant="contained"
          size="small"
          onClick={handleClickOpenTambah}
          style={{
            textTransform: "none",
            marginLeft: 10,
          }}
        >
          Tambah Cabang
        </Button>
      </Box> */}
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        customStyles={customStyles}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
      />

      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Text
            text={"Edit Cabang"}
            sx={{
              fontWeight: "600",
              fontSize: 18,
              color: "#004591",
            }}
          />
        </DialogTitle>
        <DialogContent>
          <Box sx={{ paddingTop: "15px" }}>
            <Box marginTop={3}>
              <TextField
                fullWidth={true}
                id="outlined-basic"
                label="Nama Cabang"
                variant="outlined"
                size="small"
                type={"text"}
                value={namaCabang}
                onChange={(e) => {
                  setNamaCabang(e.target.value);
                }}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              editNamaCabang({
                idCabang: idCabang,
                namaCabang: namaCabang,
              }).then((respon) => {
                if (respon === 200) {
                  getAllCabang(dispatch);
                  handleCloseEdit();
                }
              });
            }}
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        open={openTambah}
        onClose={handleCloseTambah}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Text
            text={"Tambah Cabang"}
            sx={{
              fontWeight: "600",
              fontSize: 18,
              color: "#004591",
            }}
          />
        </DialogTitle>
        <DialogContent>
          <Box sx={{ paddingTop: "15px" }}>
            <Box marginTop={3}>
              <TextField
                fullWidth={true}
                id="outlined-basic"
                label="Nama Cabang"
                variant="outlined"
                size="small"
                type={"text"}
                onChange={(e) => {
                  setNamaCabang(e.target.value);
                }}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              tambahCabangBaru({
                namaCabang: namaCabang,
              }).then((respon) => {
                if (respon === 200) {
                  getAllCabang(dispatch);
                  handleCloseTambah();
                }
              });
            }}
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Lokasi;

import "./App.css";
import React from "react";
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useParams,
} from "react-router-dom";
import { DrawerAppBar } from "./component";
import Dashboard from "./pages/dashboard";
import AllKaryawan from "./pages/karyawan/allKaryawan";
import LaporanMarketing from "./pages/laporanMarketing";
import Lokasi from "./pages/lokasi";
import Login from "./pages/login";
import { useSelector } from "react-redux";
import LaporanSpv from "./pages/laporanSpv";
import LaporanNasabah from "./pages/laporanNasabah";
import LaporanCabang from "./pages/laporanCabang";
import AssignMarketing from "./pages/laporanNasabah/assignMarketing";
import Nasabah from "./pages/nasabah";
import Pencairan from "./pages/listPencairan";

function App() {
  // const [statusLogin, setStatusLogin] = React.useState(false);
  // const auth = useSelector((state) => state.authReducer);
  let token = localStorage.getItem("token");

  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={!token ? <Login /> : <Navigate to="/" replace />}
        />

        <Route
          path="/"
          element={
            <PrivateRoot>
              <Dashboard title={"Dashboard"} />
            </PrivateRoot>
          }
        />
        <Route
          path="/listPencairan"
          element={
            <PrivateRoot>
              <Pencairan title={"Pencairan"} />
            </PrivateRoot>
          }
        />
        <Route
          path="/karyawan"
          element={
            <PrivateRoot>
              <AllKaryawan title={"Data Karyawan"} />
            </PrivateRoot>
          }
        />
        <Route
          path="/laporanSupervisor"
          element={
            <PrivateRoot>
              <LaporanSpv title={"Data Laporan Supervisor"} />
            </PrivateRoot>
          }
        />
        <Route
          path="/laporanMarketing"
          element={
            <PrivateRoot>
              <LaporanMarketing title={"Data Laporan Marketing"} />
            </PrivateRoot>
          }
        />
        <Route
          path="/laporanNasabah"
          element={
            <PrivateRoot>
              <LaporanNasabah title={"Data Calon Nasabah"} />
            </PrivateRoot>
          }
        />

        <Route
          path="/lokasi"
          element={
            <PrivateRoot>
              <Lokasi title={"Data Cabang"} />
            </PrivateRoot>
          }
        />
        <Route
          path="/laporanCabang"
          element={
            <PrivateRoot>
              <LaporanCabang title={"Data Laporan Cabang"} />
            </PrivateRoot>
          }
        />
        <Route
          path="/assignMarketing"
          element={
            <PrivateRoot>
              <AssignMarketing title={"Assign Marketing"} />
            </PrivateRoot>
          }
        />
        <Route
          path="/nasabah"
          element={
            <PrivateRoot>
              <Nasabah title={"Data Nasabah"} />
            </PrivateRoot>
          }
        />
      </Routes>
    </>
  );
}

function PrivateRoot({ children }) {
  let location = useLocation();
  let token = localStorage.getItem("tokenClient");

  return !token ? (
    <Navigate to="/login" state={{ from: location }} replace />
  ) : (
    <DrawerAppBar content={children} titleAppBar={children.props.title} />
  );
}

export default App;

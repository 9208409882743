import React from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  assignMarketingToCabang,
  getAllCabang,
  getAllMarketing,
} from "../../../stores";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
  TextField,
  Stack,
  ButtonBase,
  InputAdornment,
} from "@mui/material";
import { Text, TextInput } from "../../../component";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

function Marketing() {
  const dispatch = useDispatch();
  const karyawan = useSelector((state) => state.karyawanReducer);
  const [listCabang, setListCabang] = React.useState([]);
  const [idCabang, setIdCabang] = React.useState("");
  const [idMarketing, setIdMarketing] = React.useState("");
  const [openDialogPenempatan, setOpenDialogPenempatan] = React.useState(false);
  const [listMarketing, setListMarketing] = React.useState([]);

  React.useEffect(() => {
    getAllMarketing(dispatch);
    getAllCabang(dispatch);
  }, []);

  React.useEffect(() => {
    let tmp = karyawan.responGetCabang.map((val, index) => {
      return {
        ...val,
        label: val.namaCabang,
      };
    });
    setListCabang(tmp);
  }, [karyawan.responGetCabang]);

  const handleClickOpenPenempatan = () => {
    setOpenDialogPenempatan(true);
  };

  const handleClosePenempatan = () => {
    setOpenDialogPenempatan(false);
  };
  const customStyles = {
    rows: {
      style: {
        height: 36, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 36,
        backgroundColor: "#F8F8F8",
        fontFamily: "Poppins",
        justifyContent: "left",
      },
    },
    headCells: {
      style: {
        minHeight: 36,
        fontWeight: "bold",
        fontSize: 12,
      },
    },
    cells: {
      style: {
        minHeight: 36,
      },
    },
  };
  const columns = [
    {
      name: "No.Pegawai",
      selector: (row) => row.nopeg,
      sortable: true,
    },
    {
      name: "Nama Karyawan",
      selector: (row) => row.nama,
      sortable: true,
    },
    {
      name: "Jabatan",
      selector: (row) => row.jabatan,
    },
    {
      name: "Penempatan",
      minWidth: "400px",
      selector: (row) =>
        !row.penempatan ? (
          <Button
            color="success"
            startIcon={<PersonPinCircleIcon />}
            variant="contained"
            size="small"
            onClick={() => {
              handleClickOpenPenempatan();
              setIdMarketing(row._id);
            }}
            style={{ textTransform: "none", marginLeft: 10 }}
          >
            Penempatan
          </Button>
        ) : (
          <>
            <Stack direction={"row"} spacing={2}>
              <Box sx={{ flexGrow: 1, width: 150 }}>
                {row.penempatan.namaCabang}
              </Box>

              <Button
                startIcon={<PersonPinCircleIcon />}
                variant="contained"
                size="small"
                onClick={() => {
                  handleClickOpenPenempatan();
                  setIdMarketing(row._id);
                }}
                style={{ textTransform: "none", marginLeft: 10 }}
              >
                Ubah Penempatan
              </Button>
            </Stack>
          </>
        ),
    },
  ];

  const submitAssign = () => {
    assignMarketingToCabang(dispatch, {
      _idMarketing: idMarketing,
      _idCabang: idCabang,
    }).then((response) => {
      if (response === 200) {
        getAllMarketing(dispatch);
        getAllCabang(dispatch);
        setOpenDialogPenempatan(false);
      }
    });
  };

  React.useEffect(() => {
    let tmp = [];
    karyawan.responGetMarketing &&
      karyawan.responGetMarketing.map((val) => {
        return tmp.push(val);
      });
    setListMarketing(tmp);
  }, [karyawan.responGetMarketing]);

  //Filter
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = listMarketing.filter(
    (item) =>
      item.nopeg && item.nopeg.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <Box sx={{ width: "200px" }} marginBottom={3}>
        <TextInput
          id="standard-basic"
          variant="standard"
          type={"text"}
          onChange={(e) => setFilterText(e.target.value)}
          fullWidth={true}
          InputProps={{
            style: {
              fontSize: 12,
              fontWeight: "400",
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder={"Cari Nomor Pegawai"}
        />
      </Box>
    );
  }, [filterText, resetPaginationToggle]);
  return (
    <div>
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        customStyles={customStyles}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
      />

      <Dialog
        open={openDialogPenempatan}
        onClose={handleClosePenempatan}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Stack direction={"row"} alignContent={"center"}>
            <Box sx={{ flexGrow: 1 }}>
              <b>Penempatan</b>
            </Box>
            <ButtonBase onClick={handleClosePenempatan}>
              <CloseIcon />
            </ButtonBase>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ paddingY: "20px" }}>
          <Box sx={{ marginTop: "20px" }}>
            <Autocomplete
              // disablePortal
              id="combo-box-demo"
              options={listCabang}
              sx={{ width: 500 }}
              renderInput={(params, index) => (
                <TextField {...params} label="Nama Cabang" />
              )}
              onChange={(event, newValue) => {
                setIdCabang(newValue._id);
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            onClick={submitAssign}
            style={{
              textTransform: "none",
              marginRight: "20px",
              marginBottom: "20px",
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Marketing;

import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { Icon, Images } from "../../assets";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Text from "../text";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreIcon from "@mui/icons-material/MoreVert";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import TaskIcon from "@mui/icons-material/Task";
import PinDropIcon from "@mui/icons-material/PinDrop";
import BgLogin from "../../assets/images/bgLogin/bgLogin.png";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import Collapse from "@mui/material/Collapse";
import "./style.css";
import { logout } from "../../stores";

const drawerWidth = 240;

function DrawerAppBar(props) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const { window, content, titleAppBar } = props;
  const [selected, setSelected] = React.useState(1);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  let history = useNavigate();
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  // React.useEffect(() => {
  //   getProfile(dispatch);
  // }, []);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [openExpand, setOpenExpand] = React.useState(false);

  const handleClick = () => {
    setOpenExpand(!openExpand);
  };
  const darkTheme = createTheme({
    palette: {
      primary: {
        main: "#FFFFFF",
      },
    },
  });

  //Menu kanan
  const [anchorElRight, setAnchorElRight] = React.useState(null);
  const [mobileMoreAnchorElRight, setMobileMoreAnchorElRight] =
    React.useState(null);

  const isMenuOpenRight = Boolean(anchorElRight);
  const isMobileMenuOpenRight = Boolean(mobileMoreAnchorElRight);

  const handleProfileMenuOpenRight = (event) => {
    setAnchorElRight(event.currentTarget);
  };

  const handleMobileMenuCloseRight = () => {
    setMobileMoreAnchorElRight(null);
  };

  const handleMenuCloseRight = () => {
    setAnchorElRight(null);
    handleMobileMenuCloseRight();
  };

  const handleMobileMenuOpenRight = (event) => {
    setMobileMoreAnchorElRight(event.currentTarget);
  };

  const menuIdRight = "primary-search-account-menu";
  const renderMenuRight = (
    <Menu
      anchorEl={anchorElRight}
      id="account-menu"
      open={isMenuOpenRight}
      onClose={handleMenuCloseRight}
      onClick={handleMenuCloseRight}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {/* <MenuItem>Edit Account</MenuItem> */}
      <Divider />
      <MenuItem
        onClick={() => {
          logout();
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );

  const mobileMenuIdRight = "primary-search-account-menu-mobile";
  const renderMobileMenuRight = (
    <Menu
      anchorEl={mobileMoreAnchorElRight}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuIdRight}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpenRight}
      onClose={handleMobileMenuCloseRight}
    >
      <MenuItem onClick={handleProfileMenuOpenRight}>
        <Text
          text={"Nama User"}
          sx={{
            fontWeight: "600",
            fontSize: 14,
            color: "#004591",
          }}
        />

        <Icon.SolidChevronDownIcon
          style={{ width: 15, height: 15, marginLeft: 10 }}
        />
      </MenuItem>
    </Menu>
  );

  const drawer = (
    <div
      className="BgDrawer"
      style={{
        flex: 1,
      }}
    >
      <Images.BpdLogo style={{ marginTop: 20, marginLeft: 15, width: 130 }} />
      <Divider />
      <List sx={{ marginTop: 3.5 }}>
        <ListItem>
          <ListItemButton
            selected={selected === 1}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "rgb(87,88,89)",
              },
            }}
            // sx={{
            //   "&.Mui-selected": {
            //     color: "#2e8b57",
            //   },
            //   "&.Mui-focusVisible": {
            //     backgroundColor: "#2e8b57",
            //   },
            //   ":hover": {
            //     backgroundColor: "#2e8b57",
            //   },
            // }}
            onClick={() => {
              history("/");
              setSelected(1);
            }}
          >
            <ListItemIcon>
              <InboxIcon
                sx={{ color: selected === 1 ? "#FFFFFF" : "#B2C4E4" }}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Text
                  text={"Dashboard"}
                  sx={{
                    color: selected === 1 ? "#FFFFFF" : "#B2C4E4",
                    fontSize: 14,
                    fontWeight: selected === 1 ? "600" : "400",
                  }}
                />
              }
              sx={{
                color: selected === 1 ? "#FFFFFF" : "#B2C4E4",
                fontWeight: selected === 1 ? 600 : 400,
                fontSize: 14,
                fontFamily: "Poppins",
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            selected={selected === 2}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "rgb(87,88,89)",
              },
            }}
            // sx={{
            //   "&.Mui-selected": {
            //     color: "#2e8b57",
            //   },
            //   "&.Mui-focusVisible": {
            //     backgroundColor: "#2e8b57",
            //   },
            //   ":hover": {
            //     backgroundColor: "#2e8b57",
            //   },
            // }}
            onClick={() => {
              history("/listPencairan");
              setSelected(2);
            }}
          >
            <ListItemIcon>
              <InboxIcon
                sx={{ color: selected === 2 ? "#FFFFFF" : "#B2C4E4" }}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Text
                  text={"List Pencairan"}
                  sx={{
                    color: selected === 2 ? "#FFFFFF" : "#B2C4E4",
                    fontSize: 14,
                    fontWeight: selected === 2 ? "600" : "400",
                  }}
                />
              }
              sx={{
                color: selected === 2 ? "#FFFFFF" : "#B2C4E4",
                fontWeight: selected === 2 ? 600 : 400,
                fontSize: 14,
                fontFamily: "Poppins",
              }}
            />
          </ListItemButton>
        </ListItem>
        {/* <ListItem>
          <ListItemButton
            selected={selected === 8}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "rgb(87,88,89)",
              },
            }}
            // sx={{
            //   "&.Mui-selected": {
            //     color: "#2e8b57",
            //   },
            //   "&.Mui-focusVisible": {
            //     backgroundColor: "#2e8b57",
            //   },
            //   ":hover": {
            //     backgroundColor: "#2e8b57",
            //   },
            // }}
            onClick={() => {
              history("/laporanNasabah");
              setSelected(8);
            }}
          >
            <ListItemIcon>
              <Diversity3Icon
                sx={{ color: selected === 8 ? "#FFFFFF" : "#bbbbbd" }}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Text
                  text={"Calon Nasabah"}
                  sx={{
                    color: selected === 8 ? "#FFFFFF" : "#bbbbbd",
                    fontSize: 14,
                    fontWeight: selected === 8 ? "600" : "400",
                  }}
                />
              }
              sx={{
                color: selected === 8 ? "#FFFFFF" : "#bbbbbd",
                fontWeight: selected === 8 ? 600 : 400,
                fontSize: 14,
                fontFamily: "Poppins",
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            selected={selected === 3 || selected === 4 || selected === 7}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "rgb(87,88,89)",
              },
            }}
            // sx={{
            //   "&.Mui-selected": {
            //     color: "#2e8b57",
            //   },
            //   "&.Mui-focusVisible": {
            //     backgroundColor: "#2e8b57",
            //   },
            //   ":hover": {
            //     backgroundColor: "#2e8b57",
            //   },
            // }}
            onClick={() => {
              handleClick();
              // history("/laporan");
              // setSelected(3);
            }}
          >
            <ListItemIcon>
              <TaskIcon
                sx={{
                  color:
                    selected === 3 || selected === 4 || selected === 7
                      ? "#FFFFFF"
                      : "#bbbbbd",
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Text
                  text={"Penyaluran"}
                  sx={{
                    color:
                      selected === 3 || selected === 4 || selected === 7
                        ? "#FFFFFF"
                        : "#bbbbbd",
                    fontSize: 14,
                    fontWeight:
                      selected === 3 || selected === 4 || selected === 7
                        ? "600"
                        : "400",
                  }}
                />
              }
              sx={{
                color:
                  selected === 3 || selected === 4 || selected === 7
                    ? "#FFFFFF"
                    : "#bbbbbd",
                fontWeight:
                  selected === 3 || selected === 4 || selected === 7
                    ? 600
                    : 400,
                fontSize: 14,
                fontFamily: "Poppins",
              }}
            />
            {openExpand ? (
              <ExpandLess
                sx={{
                  color:
                    selected === 3 || selected === 4 || selected === 7
                      ? "#FFFFFF"
                      : "#bbbbbd",
                }}
              />
            ) : (
              <ExpandMore
                sx={{
                  color:
                    selected === 3 || selected === 4 || selected === 7
                      ? "#FFFFFF"
                      : "#bbbbbd",
                }}
              />
            )}
          </ListItemButton>
        </ListItem>
        <Collapse in={openExpand} timeout="auto" unmountOnExit>
          <ListItem>
            <ListItemButton
              selected={selected === 4}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "rgb(87,88,89)",
                },
                marginLeft: 3,
              }}
              // sx={{
              //   "&.Mui-selected": {
              //     color: "#2e8b57",
              //   },
              //   "&.Mui-focusVisible": {
              //     backgroundColor: "#2e8b57",
              //   },
              //   ":hover": {
              //     backgroundColor: "#2e8b57",
              //   },
              // }}
              onClick={() => {
                history("/laporanMarketing");
                setSelected(4);
              }}
            >
              <ListItemIcon>
                <PersonSearchIcon
                  sx={{ color: selected === 4 ? "#FFFFFF" : "#bbbbbd" }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Text
                    text={"Marketing"}
                    sx={{
                      color: selected === 4 ? "#FFFFFF" : "#bbbbbd",
                      fontSize: 14,
                      fontWeight: selected === 4 ? "600" : "400",
                    }}
                  />
                }
                sx={{
                  color: selected === 4 ? "#FFFFFF" : "#bbbbbd",
                  fontWeight: selected === 4 ? 600 : 400,
                  fontSize: 14,
                  fontFamily: "Poppins",
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              selected={selected === 7}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "rgb(87,88,89)",
                },
                marginLeft: 3,
              }}
              // sx={{
              //   "&.Mui-selected": {
              //     color: "#2e8b57",
              //   },
              //   "&.Mui-focusVisible": {
              //     backgroundColor: "#2e8b57",
              //   },
              //   ":hover": {
              //     backgroundColor: "#2e8b57",
              //   },
              // }}
              onClick={() => {
                history("/laporanCabang");
                setSelected(7);
              }}
            >
              <ListItemIcon>
                <PersonSearchIcon
                  sx={{ color: selected === 7 ? "#FFFFFF" : "#bbbbbd" }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Text
                    text={"Cabang"}
                    sx={{
                      color: selected === 7 ? "#FFFFFF" : "#bbbbbd",
                      fontSize: 14,
                      fontWeight: selected === 7 ? "600" : "400",
                    }}
                  />
                }
                sx={{
                  color: selected === 7 ? "#FFFFFF" : "#bbbbbd",
                  fontWeight: selected === 7 ? 600 : 400,
                  fontSize: 14,
                  fontFamily: "Poppins",
                }}
              />
            </ListItemButton>
          </ListItem>
        </Collapse>
        <ListItem>
          <ListItemButton
            selected={selected === 5}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "rgb(87,88,89)",
              },
            }}
            // sx={{
            //   "&.Mui-selected": {
            //     color: "#2e8b57",
            //   },
            //   "&.Mui-focusVisible": {
            //     backgroundColor: "#2e8b57",
            //   },
            //   ":hover": {
            //     backgroundColor: "#2e8b57",
            //   },
            // }}
            onClick={() => {
              history("/nasabah");
              setSelected(5);
            }}
          >
            <ListItemIcon>
              <Diversity3Icon
                sx={{ color: selected === 5 ? "#FFFFFF" : "#bbbbbd" }}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Text
                  text={"Data Nasabah"}
                  sx={{
                    color: selected === 5 ? "#FFFFFF" : "#bbbbbd",
                    fontSize: 14,
                    fontWeight: selected === 5 ? "600" : "400",
                  }}
                />
              }
              sx={{
                color: selected === 5 ? "#FFFFFF" : "#bbbbbd",
                fontWeight: selected === 5 ? 600 : 400,
                fontSize: 14,
                fontFamily: "Poppins",
              }}
            />
          </ListItemButton>
        </ListItem>
        
        <ListItem>
          <ListItemButton
            selected={selected === 6}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "rgb(87,88,89)",
              },
            }}
            // sx={{
            //   "&.Mui-selected": {
            //     color: "#2e8b57",
            //   },
            //   "&.Mui-focusVisible": {
            //     backgroundColor: "#2e8b57",
            //   },
            //   ":hover": {
            //     backgroundColor: "#2e8b57",
            //   },
            // }}
            onClick={() => {
              history("/lokasi");
              setSelected(6);
            }}
          >
            <ListItemIcon>
              <PinDropIcon
                sx={{ color: selected === 6 ? "#FFFFFF" : "#bbbbbd" }}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Text
                  text={"List Cabang"}
                  sx={{
                    color: selected === 6 ? "#FFFFFF" : "#bbbbbd",
                    fontSize: 14,
                    fontWeight: selected === 6 ? "600" : "400",
                  }}
                />
              }
              sx={{
                color: selected === 6 ? "#FFFFFF" : "#bbbbbd",
                fontWeight: selected === 6 ? 600 : 400,
                fontSize: 14,
                fontFamily: "Poppins",
              }}
            />
          </ListItemButton>
        </ListItem> */}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <ThemeProvider theme={darkTheme}>
        <AppBar
          // elevation={0}
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box>
              <Text
                text={titleAppBar}
                sx={{
                  flexGrow: 1,
                  fontWeight: "600",
                  fontSize: 20,
                  color: "#004591",
                }}
              />
            </Box>

            <Box sx={{ flexGrow: 1 }} />
            {/* <Icon.BellNotifIcon style={{ width: 20, height: 20 }} /> */}
            <Box
              sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
            >
              <Text
                text={"Client"}
                sx={{
                  fontWeight: "600",
                  fontSize: 14,
                  color: "#004591",
                  marginLeft: 5,
                }}
              />
              <Box>
                <Button
                  color="inherit"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleProfileMenuOpenRight}
                >
                  <Icon.SolidChevronDownIcon
                    style={{ width: 15, height: 15 }}
                  />
                </Button>
              </Box>
              <Box>
                <Images.LogoBapDark style={{ height: 20 }} />
              </Box>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuIdRight}
                aria-haspopup="true"
                onClick={handleMobileMenuOpenRight}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
          <Divider />
        </AppBar>
        {renderMobileMenuRight}
        {renderMenuRight}
      </ThemeProvider>

      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Box>{content}</Box>
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;

import React from "react";
import { Images } from "../../assets";
import { ButtonPrimary, Text, TextInput } from "../../component";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Stack,
  Box,
  ButtonBase,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BgLogin from "../../assets/images/bgLogin/bgLogin.png";
import { login } from "../../stores";
import MuiAlert from "@mui/material/Alert";

function Login() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [values, setValues] = React.useState({
    nopeg: "",
    password: "",
    showPassword: false,
  });
  const [messageAlert, setMessageAlert] = React.useState(null);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [typeAlert, setTypeAlert] = React.useState("success");
  const [loading, setLoading] = React.useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
    setMessageAlert(null);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleChangeEmail = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const masuk = () => {
    setLoading(true);
    if (!values.password) {
      setLoading(false);
      setTypeAlert("warning");
      setMessageAlert("Password tidak boleh kosong!");
      setOpenAlert(true);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } else {
      login(dispatch, { password: values.password })
        .then((respon) => {
          if (respon.status === 401) {
            setTypeAlert("warning");
            setMessageAlert(respon.data.message);
            setOpenAlert(true);
            setTimeout(() => {
              setLoading(false);
            }, 2000);
          } else if (respon.status === 200) {
            setTypeAlert("success");
            setMessageAlert(respon.data.message);
            setOpenAlert(true);
            setTimeout(() => {
              history("/");
              setLoading(false);
            }, 2000);
            localStorage.setItem("tokenClient", respon.data.token);
            dispatch({ type: "SET_LOGIN", data: respon.data });
          }
        })
        .catch((err) => {
          setLoading(false);
          setTypeAlert("error");
          setMessageAlert("Maaf server sedang offline!");
          setOpenAlert(true);
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        });
    }

    // login(dispatch, values, history).then((val) => {
    //   if (val.code === 200) {
    //     setTypeAlert("success");
    //     setMessageAlert(val.message);
    //     setOpenAlert(true);
    //     setTimeout(() => {
    //       history("/");
    //     }, 2000);
    //   } else {
    //     console.log(val);
    //     setTypeAlert("error");
    //     setMessageAlert(val.message);
    //     setOpenAlert(true);
    //   }
    // });
    // history("/");
  };
  return (
    <>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={typeAlert}
          sx={{ width: "100%" }}
        >
          {messageAlert}
        </Alert>
      </Snackbar>
      <Grid container direction={"row"}>
        <Grid xs={12} sm={7}>
          <Stack
            paddingX={{ xs: 5, md: 10, lg: 25 }}
            justifyContent={"center"}
            height={"100vh"}
          >
            <Box textAlign={"center"}>
              <Images.BpdLogo style={{ height: 80 }} />
            </Box>
            <Box textAlign={"center"} sx={{ marginTop: 3 }}>
              {/* <Text
                text={"Login"}
                sx={{ fontSize: 20, color: "#959595", fontWeight: "600" }}
              /> */}
            </Box>
            {/* <Box sx={{ marginTop: 5 }}>
              <Text
                text={"Username"}
                sx={{ fontSize: 12, color: "#959595", fontWeight: "400" }}
              />
            </Box>
            <Box sx={{ marginTop: 2 }}>
              <TextInput
                id="standard-basic"
                variant="standard"
                type={"text"}
                onChange={handleChangeEmail("nopeg")}
                fullWidth={true}
                InputProps={{
                  style: {
                    fontSize: 12,
                    fontWeight: "400",
                  },
                }}
                placeholder={"Username"}
              />
            </Box> */}
            <Box sx={{ marginTop: 5 }}>
              <Text
                text={"Password"}
                sx={{ fontSize: 12, color: "#959595", fontWeight: "400" }}
              />
            </Box>
            <Box sx={{ marginTop: 2 }}>
              <TextInput
                id="standard-basic"
                variant="standard"
                fullWidth={true}
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                placeholder={"Your password here"}
                InputProps={{
                  style: {
                    fontSize: 12,
                    fontWeight: "400",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <VisibilityOff
                            sx={{ width: 16, height: 16, marginRight: 2 }}
                          />
                        ) : (
                          <Visibility
                            sx={{ width: 16, height: 16, marginRight: 2 }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              display={"flex"}
              sx={{
                marginTop: 5,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {loading ? (
                <CircularProgress size={25} thickness={7} />
              ) : (
                <ButtonPrimary
                  onClick={masuk}
                  text={"Login"}
                  variant={"contained"}
                  sx={{
                    width: "100%",
                    height: 32,
                    backgroundColor: "#3067FF",
                    textTransform: "none",
                    fontWeight: "600",
                    fontSize: 12,
                  }}
                />
              )}
            </Box>
            {/* <Box
              sx={{
                marginTop: "23px",
                textAlign: "end",
              }}
            >
              <ButtonBase>
                <Text
                  text={"Forgot password"}
                  sx={{
                    fontSize: 12,
                    color: "#959595",
                  }}
                />
              </ButtonBase>
            </Box> */}
          </Stack>
        </Grid>
        <Grid xs={12} sm={5} display={{ xs: "none", sm: "block", md: "block" }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundImage: `url(${BgLogin})`,
              backgroundSize: "cover",
              height: "100vh",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                height: "100vh",
                width: "40%",
                paddingTop: 5,
              }}
            >
              <Images.LogoBap style={{ height: 40 }} />
            </Box>
            <Grid direction={"column"}>
              <Grid direction={"column"}>
                <Grid>
                  <Text
                    text={"Welcome to BPD"}
                    sx={{ fontSize: 40, color: "#FFFFFF", fontWeight: "600" }}
                  />
                </Grid>
                <Grid>
                  <Text
                    text={"Kalimantan Selatan"}
                    sx={{ fontSize: 40, color: "#FFFFFF", fontWeight: "600" }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Box
              display={"flex"}
              justifyContent="flex-end"
              alignItems={"flex-end"}
              sx={{
                position: "absolute",
                height: "100vh",
                width: "40%",
                paddingBottom: 2,
              }}
            >
              <Text
                text={"© 2023 PT. Bina Area Persada"}
                sx={{ fontSize: 12, color: "#FFFFFF", fontWeight: "600" }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Login;

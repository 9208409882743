import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { reduxSoldierMiddleware } from "redux-soldier";
import axios from "axios";

//reducer
import authReducer from "./auth";
import karyawanReducer from "./karyawan";
import dashboardReducer from "./dashboard";

//function
export * from "./auth/function";
export * from "./karyawan/function";
export * from "./dashboard/function";

//koneksi axios
export const baseUrl = "https://apimonitorkalsel.ptbap.net/";
export const baseAxios = axios.create({
  baseURL: baseUrl,
});

const rootReducer = combineReducers({
  authReducer,
  karyawanReducer,
  dashboardReducer,
});

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(reduxSoldierMiddleware))
);

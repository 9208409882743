import { baseAxios } from "../index";

export async function updateKaryawan(dispatch, data, history) {
  try {
    const response = await baseAxios("user/updateKaryawan", {
      headers: { token: localStorage.getItem("token") },
    });
    console.log(response);
  } catch (err) {
    console.log(err.response);
  }
}

export async function getAllSupervisor(dispatch, data, history) {
  try {
    const response = await baseAxios("user/findAllSPV", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALLSUPERVISOR", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function getAllMarketing(dispatch, data, history) {
  try {
    const response = await baseAxios("user/findAllMarketing", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALLMARKETING", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function getAllDirectSales(dispatch, data, history) {
  try {
    const response = await baseAxios("user/findAllDirectSales", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALLDIRECTSALES", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function getAllCabang(dispatch, data, history) {
  try {
    const response = await baseAxios("user/getAllCabang", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_CABANG", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function assignSpvToCabang(dispatch, data, history) {
  try {
    const response = await baseAxios.patch("user/assignCabangtoSPV", data, {
      headers: { token: localStorage.getItem("token") },
    });
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function assignMarketingToCabang(dispatch, data, history) {
  try {
    const response = await baseAxios.patch(
      "user/assignMarketingtocabang",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function assignDirectSalesToCabang(dispatch, data, history) {
  try {
    const response = await baseAxios.patch(
      "user/assignDirectSalestocabang",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function uploadPenyaluranMarketing(data) {
  try {
    const response = await baseAxios.post(
      "penyaluran/importHarianExcel",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.status;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

export async function getAllPenyaluran(dispatch, data, history) {
  try {
    const response = await baseAxios("penyaluran/getAllPenyaluran", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_PENYALURAN", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function getPenyaluranTiapCabang(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `penyaluran/penyaluranTiapCabang?from=${data.from}&to=${data.to}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_PENYALURANCABANG", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function getPenyaluranTiapCabang2(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `penyaluran/getTotalPenyaluran?from=${data.from}&to=${data.to}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_PENYALURANCABANG", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function uploadPenyaluranNasabah(data) {
  try {
    const response = await baseAxios.post("penyaluran/importTempoUser", data, {
      headers: {
        token: localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    });

    return response.status;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

export async function getPenyaluranNasabah1(dispatch, data, history) {
  try {
    const response = await baseAxios(`marketing/getnasabah`, {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_PENYALURANNASABAH1", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function getPenugasanMarketing(dispatch, data, history) {
  let { idMarketing, tanggal } = data;
  try {
    const response = await baseAxios(
      `marketing/getmarketingtugas?idMarketing=${idMarketing}&tanggal=${tanggal}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_RESPONPENUGASANMARKETING", data: response.data });
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export async function assignPenugasanMarketing(data) {
  try {
    const response = await baseAxios.post(
      "marketing/assignMarketingToNasabah",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    return response.status;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

export async function editPenyaluranMarketing(data) {
  try {
    const response = await baseAxios.patch("penyaluran/editpenyaluran", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    return response.status;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

export async function uploadNasabahAcc(data) {
  try {
    const response = await baseAxios.post("nasabah/importNasabah", data, {
      headers: {
        token: localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    });

    return response.status;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

export async function getNasabahAcc(dispatch, data, history) {
  try {
    const response = await baseAxios(`nasabah/nasabahAcc`, {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_RESPONNASABAH", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function editNamaCabang(data) {
  // console.log(data, "??????");
  try {
    const response = await baseAxios.patch("user/updateNamaCabang", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    return response.status;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

export async function deleteCabang(data) {
  try {
    const response = await baseAxios.delete("user/deleteCabangs", {
      data: { idCabang: data },
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    return response.status;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

export async function tambahCabangBaru(data) {
  try {
    const response = await baseAxios.post("user/tambahCabangBaru", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    return response.status;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

export async function getPenyaluranTotalPenyaluran(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `dashboard/dashboardtotalpenyaluran?from=${data.from}&to=${data.to}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getTotalTarget(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `dashboard/dashboardtotaltarget?from=${data.from}&to=${data.to}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getTotalSPV(dispatch, data, history) {
  try {
    const response = await baseAxios(`dashboard/dashboardtotalspv`, {
      headers: { token: localStorage.getItem("token") },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getTotalMarketing(dispatch, data, history) {
  try {
    const response = await baseAxios(`dashboard/dashboardtotalmarketing`, {
      headers: { token: localStorage.getItem("token") },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getTotalCabang(dispatch, data, history) {
  try {
    const response = await baseAxios(`dashboard/dashboardtotalcabang`, {
      headers: { token: localStorage.getItem("token") },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllPenyaluranMarketing(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `penyaluran/penyaluranTiapMarketing?from=${data.from}&to=${data.to}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_PENYALURAN_MARKETING", data: response.data });
  } catch (err) {
    return err.response;
  }
}

import { baseAxios } from "../index";

export async function getJumlahPencairan(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `dashboard/jumlahpencairan?from=${data.from}&to=${data.to}`,
      {
        headers: { token: localStorage.getItem("tokenClient") },
      }
    );
    dispatch({ type: "SET_JUMLAHPENCAIRAN", data: response.data.value });
  } catch (err) {
    return err.response;
  }
}

export async function getDashboardTotalMarketing(dispatch, data, history) {
  try {
    const response = await baseAxios("dashboard/totalmarketing", {
      headers: { token: localStorage.getItem("tokenClient") },
    });
    dispatch({ type: "SET_JUMLAHMARKETING", data: response.data.value });
  } catch (err) {
    return err.response;
  }
}

export async function getDashboardTotalSpv(dispatch, data, history) {
  try {
    const response = await baseAxios("dashboard/totalspv", {
      headers: { token: localStorage.getItem("tokenClient") },
    });
    dispatch({ type: "SET_JUMLAHSPV", data: response.data.value });
  } catch (err) {
    return err.response;
  }
}

export async function getDashboardTotalCabang(dispatch, data, history) {
  try {
    const response = await baseAxios("dashboard/totalcabang", {
      headers: { token: localStorage.getItem("tokenClient") },
    });
    dispatch({ type: "SET_JUMLAHCABANG", data: response.data.value });
  } catch (err) {
    return err.response;
  }
}

export async function getDashboardGrafikCabang(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `dashboard/grafikcabang?from=${data.from}&to=${data.to}`,
      {
        headers: { token: localStorage.getItem("tokenClient") },
      }
    );
    dispatch({ type: "SET_GRAFIKCABANG", data: response.data.value });
  } catch (err) {
    return err.response;
  }
}

export async function getDashboardJumlahTarget(dispatch, data, history) {
  try {
    const response = await baseAxios("dashboard/jumlahtarget", {
      headers: { token: localStorage.getItem("tokenClient") },
    });
    dispatch({ type: "SET_JUMLAHTARGET", data: response.data.value });
  } catch (err) {
    return err.response;
  }
}

export async function getListPencairan(dispatch, data, history) {
  try {
    const response = await baseAxios("pencairan/listpencairan", {
      headers: { token: localStorage.getItem("tokenClient") },
    });
    dispatch({ type: "SET_LISTPENCAIRAN", data: response.data });
  } catch (err) {
    return err.response;
  }
}

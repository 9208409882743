import React from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Box,
  Paper,
  Stack,
  ButtonBase,
  Autocomplete,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { Text, TextInput } from "../../component";
import moment from "moment";
import {
  getNasabahAcc,
  getPenyaluranNasabah1,
  uploadNasabahAcc,
  uploadPenyaluranNasabah,
} from "../../stores";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

function Nasabah() {
  let history = useNavigate();
  const dispatch = useDispatch();
  const karyawan = useSelector((state) => state.karyawanReducer);
  const [open, setOpen] = React.useState(false);
  const [openDetailNasabah, setOpenDetailNasabah] = React.useState(false);
  const [uploadPenyaluran, setUploadPenyaluran] = React.useState(null);
  const [namaFile, setNamaFile] = React.useState("");
  const [listNasabah, setListNasabah] = React.useState([]);
  const [detailNasabah, setDetailNasabah] = React.useState([]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenDetailNasabah = () => {
    setOpenDetailNasabah(true);
  };

  const handleCloseDetailNasabah = () => {
    setOpenDetailNasabah(false);
  };

  const customStyles = {
    rows: {
      style: {
        height: 36, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 36,
        backgroundColor: "#F8F8F8",
        fontFamily: "Poppins",
        justifyContent: "left",
      },
    },
    headCells: {
      style: {
        minHeight: 36,
        fontWeight: "bold",
        fontSize: 12,
      },
    },
    cells: {
      style: {
        minHeight: 36,
      },
    },
  };

  //Filter
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems =
    listNasabah &&
    listNasabah.filter(
      (item) =>
        item.Nama_Nasabah &&
        item.Nama_Nasabah.toLowerCase().includes(filterText.toLowerCase())
    );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Box sx={{ width: "200px" }} marginBottom={3}>
        <TextInput
          id="standard-basic"
          variant="standard"
          type={"text"}
          onChange={(e) => setFilterText(e.target.value)}
          fullWidth={true}
          InputProps={{
            style: {
              fontSize: 12,
              fontWeight: "400",
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder={"Cari Nama Nasabah"}
        />
      </Box>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "Nama Nasabah",
      minWidth: "200px",
      selector: (row) => row.Nama_Nasabah,
    },
    {
      name: "NIP/NOPEN",
      minWidth: "200px",
      selector: (row) => (!row.NIP_NOPEN ? "-" : row.NIP_NOPEN),
    },
    {
      name: "NIK KTP",
      minWidth: "200px",
      selector: (row) => row.NIK_KTP,
    },
    {
      name: "Jenis Kredit",
      minWidth: "200px",
      selector: (row) => row.Jenis_Kredit,
    },
    {
      name: "No Handphone",
      minWidth: "200px",
      selector: (row) => (!row.No_Handphone ? "-" : row.No_Handphone),
    },
    {
      name: "Plafond",
      minWidth: "200px",
      selector: (row) => row.plafon,
    },
    {
      name: "Pelunasan",
      minWidth: "200px",
      selector: (row) => row.pelunasan,
    },
    {
      name: "Netto",
      minWidth: "200px",
      selector: (row) => row.netto,
    },
    {
      name: "Tanggal Realisasi",
      minWidth: "200px",
      selector: (row) => row.tglRealisasi,
    },
    {
      name: "Tanggal Pelunasan",
      minWidth: "200px",
      selector: (row) => row.tglPelunasan,
    },
    {
      name: "Tanggal Mutasi",
      minWidth: "200px",
      selector: (row) => row.tglMutasi,
    },
    {
      name: "Tanggal Pengambilan Jaminan",
      minWidth: "200px",
      selector: (row) => row.tglPengambilanJaminan,
    },
    {
      name: "Kantor Bayar Lama",
      minWidth: "200px",
      selector: (row) => row.Kantor_Bayar_Lama,
    },
    {
      name: "Supervisor",
      minWidth: "200px",
      selector: (row) => row.Nama_SS,
    },
    {
      name: "Marketing",
      minWidth: "200px",
      selector: (row) => row.Nama_SM,
    },
    {
      name: "Lokasi Kerja",
      minWidth: "200px",
      selector: (row) => row.Lokasi_Kerja,
    },
    {
      name: "No Rekening",
      minWidth: "200px",
      selector: (row) => (!row.No_Rekening ? "-" : row.No_Rekening),
    },
    {
      name: "No Pinjaman",
      minWidth: "200px",
      selector: (row) => (!row.No_Pinjaman ? "-" : row.No_Pinjaman),
    },
    {
      name: "Asuransi",
      minWidth: "200px",
      selector: (row) => (!row.Asuransi ? "-" : row.Asuransi),
    },
  ];

  const submit = (e) => {
    e.preventDefault();
    let fd = new FormData();
    fd.append("excel", uploadPenyaluran);
    uploadNasabahAcc(fd).then((response) => {
      if (response === 200) {
        handleClose();
        setUploadPenyaluran(null);
        setNamaFile("");
        getNasabahAcc(dispatch);
        // getAllPenyaluran(dispatch);
      }
    });
  };

  React.useEffect(() => {
    getNasabahAcc(dispatch);
  }, []);

  React.useEffect(() => {
    let tmp =
      karyawan.responListNasabah.data &&
      karyawan.responListNasabah.data.map((val) => {
        return {
          ...val,
          plafon: !val.Plafond ? (
            "-"
          ) : (
            <NumericFormat
              value={val.Plafond}
              thousandSeparator={true}
              prefix={"Rp"}
              displayType={"text"}
            />
          ),
          pelunasan: !val.Pelunasan ? (
            "-"
          ) : (
            <NumericFormat
              value={val.Pelunasan}
              thousandSeparator={true}
              prefix={"Rp"}
              displayType={"text"}
            />
          ),
          netto: (
            <NumericFormat
              value={val.Netto}
              thousandSeparator={true}
              prefix={"Rp"}
              displayType={"text"}
            />
          ),
          tglRealisasi: !val.Tgl_Realisasi
            ? "-"
            : moment(val.Tgl_Realisasi).format("DD MMMM YYYY"),
          tglPelunasan: !val.Tgl_Pelunasan
            ? "-"
            : moment(val.Tgl_Pelunasan).format("DD MMMM YYYY"),
          tglMutasi: !val.Tgl_Mutasi
            ? "-"
            : moment(val.Tgl_Mutasi).format("DD MMMM YYYY"),
          tglPengambilanJaminan: !val.Tgl_Pengambilan_Jaminan
            ? "-"
            : moment(val.Tgl_Pengambilan_Jaminan).format("DD MMMM YYYY"),
        };
      });
    setListNasabah(tmp);
  }, [karyawan.responListNasabah]);

  return (
    <div>
      {/* <Stack flexDirection={"row"}>
        <Box flexGrow={1}>
          <Button
            color="success"
            // startIcon={<PersonPinCircleIcon />}
            variant="contained"
            size="small"
            onClick={handleClickOpen}
            style={{ textTransform: "none", marginLeft: 10, marginBottom: 20 }}
          >
            Upload Data Nasabah
          </Button>
        </Box>
      </Stack> */}

      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        customStyles={customStyles}
        // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
      />

      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Text
            text={"Upload Data Nasabah"}
            sx={{
              fontWeight: "600",
              fontSize: 18,
              color: "#004591",
            }}
          />
        </DialogTitle>
        <DialogContent>
          <Stack direction={"row"} alignItems={"center"}>
            <Button
              color="success"
              // startIcon={<PersonPinCircleIcon />}
              variant="contained"
              size="small"
              component="label"
              // onClick={() => {}}
              onChange={(e) => {
                e.preventDefault();
                setUploadPenyaluran(e.target.files[0]);
                setNamaFile(e.target.files[0].name);
              }}
              style={{
                textTransform: "none",
                marginRight: 10,
              }}
            >
              Upload Data Nasabah
              <input hidden type="file" />
            </Button>
            <Box>
              <Text
                text={namaFile === "" ? "Tidak ada file" : namaFile}
                sx={{ color: "grey" }}
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={submit} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        open={openDetailNasabah}
        onClose={handleCloseDetailNasabah}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Text
            text={"Detail Penyaluran Nasabah"}
            sx={{
              fontWeight: "600",
              fontSize: 18,
              color: "#004591",
            }}
          />
        </DialogTitle>
        <DialogContent>
          <Grid container direction={"row"}>
            <Grid xs={6}>
              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={4}>
                    <Text
                      text={"Kode Nasabah"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={7}>
                    <Text
                      text={detailNasabah.kodeNasabah}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={4}>
                    <Text
                      text={"Induk"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={7}>
                    <Text
                      text={detailNasabah.induk}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={4}>
                    <Text
                      text={"Nama Nasabah"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={7}>
                    <Text
                      text={detailNasabah.nama}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={4}>
                    <Text
                      text={"No HP"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={7}>
                    <Text
                      text={detailNasabah.noHp}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={4}>
                    <Text
                      text={"Alamat"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={7}>
                    <Text
                      text={detailNasabah.alamat}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={4}>
                    <Text
                      text={"Kecamatan"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={7}>
                    <Text
                      text={detailNasabah.kecamatan}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={4}>
                    <Text
                      text={"Kelurahan"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={7}>
                    <Text
                      text={detailNasabah.kelurahan}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={4}>
                    <Text
                      text={"Produk"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={7}>
                    <Text
                      text={detailNasabah.produk}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={4}>
                    <Text
                      text={"Jatuh Tempo"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={7}>
                    <Text
                      text={moment(detailNasabah.jatuhTempo).format(
                        "DD MMMM YYYY"
                      )}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={4}>
                    <Text
                      text={"Outstanding"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={7}>
                    <Text
                      text={detailNasabah.outstanding}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={4}>
                    <Text
                      text={"Plafon"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={7}>
                    <Text
                      text={detailNasabah.plafon}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {/* <Grid
              xs={6}
              justifyContent={"center"}
              alignItems={"center"}
              display={"flex"}
            >
              <Paper
                elevation={3}
                sx={{ width: "100%", height: "100%", textAlign: "center" }}
              >
                <Box display={"flex"} height={"100%"} justifyContent={"center"}>
                  <img src="https://winaero.com/blog/wp-content/uploads/2019/11/Photos-new-icon.png" />
                </Box>
              </Paper>
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetailNasabah} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Nasabah;

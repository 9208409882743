import React from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  assignSpvToCabang,
  getAllCabang,
  getAllSupervisor,
} from "../../../stores";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
  TextField,
  Stack,
  ButtonBase,
  InputAdornment,
} from "@mui/material";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import CloseIcon from "@mui/icons-material/Close";
import { Text, TextInput } from "../../../component";
import SearchIcon from "@mui/icons-material/Search";

function Supervisor() {
  const dispatch = useDispatch();
  const karyawan = useSelector((state) => state.karyawanReducer);
  const [cabang, setCabang] = React.useState([]);
  const [listCabang, setListCabang] = React.useState([]);
  const [idCabang, setIdCabang] = React.useState("");
  const [idSPV, setIdSPV] = React.useState("");
  const [listSpv, setListSpv] = React.useState([]);
  const [openDialogDetail, setOpenDialogDetail] = React.useState(false);
  const [openDialogPenempatan, setOpenDialogPenempatan] = React.useState(false);

  React.useEffect(() => {
    getAllSupervisor(dispatch);
    getAllCabang(dispatch);
  }, []);

  React.useEffect(() => {
    let tmp = karyawan.responGetCabang.map((val, index) => {
      return {
        ...val,
        label: val.namaCabang,
      };
    });
    setListCabang(tmp);
  }, [karyawan.responGetCabang]);

  const handleClickOpenDetail = () => {
    setOpenDialogDetail(true);
  };

  const handleCloseDetail = () => {
    setOpenDialogDetail(false);
  };

  const handleClickOpenPenempatan = () => {
    setOpenDialogPenempatan(true);
  };

  const handleClosePenempatan = () => {
    setOpenDialogPenempatan(false);
  };

  const submitAssign = () => {
    assignSpvToCabang(dispatch, { _idSPV: idSPV, _idCabang: idCabang }).then(
      (response) => {
        if (response === 200) {
          getAllSupervisor(dispatch);
          getAllCabang(dispatch);
          setOpenDialogPenempatan(false);
        }
      }
    );
  };

  const customStyles = {
    rows: {
      style: {
        height: 36, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 36,
        backgroundColor: "#F8F8F8",
        fontFamily: "Poppins",
        justifyContent: "left",
      },
    },
    headCells: {
      style: {
        minHeight: 36,
        fontWeight: "bold",
        fontSize: 12,
      },
    },
    cells: {
      style: {
        minHeight: 36,
      },
    },
  };
  const columns = [
    {
      name: "No.Pegawai",
      selector: (row) => row.Identitas.nopeg,
      sortable: true,
    },
    {
      name: "Nama Karyawan",
      minWidth: "200px",
      selector: (row) => row.Identitas.nama,
      sortable: true,
    },
    {
      name: "Jabatan",
      minWidth: "150px",
      selector: (row) => row.Identitas.jabatan,
    },
    {
      name: "Penempatan",
      minWidth: "250px",
      selector: (row) =>
        row.cabang.length != 0 ? (
          <>
            <Button
              startIcon={<FindInPageIcon />}
              variant="contained"
              size="small"
              onClick={() => {
                handleClickOpenDetail();
                setCabang(row.cabang);
              }}
              style={{ textTransform: "none" }}
            >
              Detail
            </Button>
            <Button
              color="success"
              startIcon={<PersonPinCircleIcon />}
              variant="contained"
              size="small"
              onClick={() => {
                handleClickOpenPenempatan();
                setIdSPV(row._id);
              }}
              style={{ textTransform: "none", marginLeft: 10 }}
            >
              Penempatan
            </Button>
          </>
        ) : (
          <Button
            color="success"
            startIcon={<PersonPinCircleIcon />}
            variant="contained"
            size="small"
            onClick={() => {
              console.log(row._id, "{{{{{{{{{{{{");
              handleClickOpenPenempatan();
              setIdSPV(row._id);
            }}
            style={{ textTransform: "none" }}
          >
            Penempatan
          </Button>
        ),
    },
  ];

  React.useEffect(() => {
    let tmp = [];
    karyawan.responGetSupervisor &&
      karyawan.responGetSupervisor.map((val) => {
        return tmp.push({
          ...val,
          nama: val.Identitas.nama,
          nopeg: val.Identitas.nopeg,
        });
      });
    setListSpv(tmp);
  }, [karyawan.responGetSupervisor]);
  //Filter
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems =
    listSpv &&
    listSpv.filter(
      (item) =>
        item.nopeg &&
        item.nopeg.toLowerCase().includes(filterText.toLowerCase())
    );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <Box sx={{ width: "200px" }} marginBottom={3}>
        <TextInput
          id="standard-basic"
          variant="standard"
          type={"text"}
          onChange={(e) => setFilterText(e.target.value)}
          fullWidth={true}
          InputProps={{
            style: {
              fontSize: 12,
              fontWeight: "400",
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder={"Cari Nomor Pegawai"}
        />
      </Box>
    );
  }, [filterText, resetPaginationToggle]);

  console.log(listSpv, ">>>>>>>>>>");
  return (
    <div>
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        customStyles={customStyles}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
      />

      <Dialog
        open={openDialogDetail}
        onClose={handleCloseDetail}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <b>List Penempatan Supervisor</b>
        </DialogTitle>
        <DialogContent>
          <ol>
            {cabang.map((val, i) => {
              return <li>{val.namaCabang}</li>;
            })}
          </ol>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetail}>Ok</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogPenempatan}
        onClose={handleClosePenempatan}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Stack direction={"row"} alignContent={"center"}>
            <Box sx={{ flexGrow: 1 }}>
              <b>Penempatan</b>
            </Box>
            <ButtonBase onClick={handleClosePenempatan}>
              <CloseIcon />
            </ButtonBase>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ paddingY: "20px" }}>
          <Box sx={{ marginTop: "20px" }}>
            <Autocomplete
              // disablePortal
              id="combo-box-demo"
              options={listCabang}
              sx={{ width: 500 }}
              renderInput={(params, index) => (
                <TextField {...params} label="Nama Cabang" />
              )}
              onChange={(event, newValue) => {
                setIdCabang(newValue._id);
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            onClick={submitAssign}
            style={{
              textTransform: "none",
              marginRight: "20px",
              marginBottom: "20px",
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Supervisor;

import { baseAxios } from "../index";

export async function login(dispatch, data, history) {
  try {
    const response = await baseAxios.post("user/loginwithexp", data);
    console.log(response)
    return response;
  } catch (err) {
    console.log(err)
    return err.response;
  }
  // let body = {
  //   idPerusahaan: "162",
  //   nopeg: data.nopeg,
  //   password: data.password,
  // };
  // try {
  //   const response = await baseAxios.post("user/loginWeb", body);
  //   dispatch({ type: "SET_LOGIN", data: response.data });
  //   localStorage.setItem("token", response.data.data.token);
  //   return response.data;
  // } catch (err) {
  //   return err.response.data;
  // }
}

export async function logout(dispatch, data, history) {
  localStorage.removeItem("tokenClient");
  window.location.reload();
}
